import './App.css';

import Wheel from './components/Wheel.js'

function App() {
  //  Counter is a state initialized to 0
  return (
    <div className="App">
      <h1>ארץ עיר</h1>
      <Wheel/>
    </div>
  );
}

export default App;
