// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background: hsla(270, 72%, 69%, 1);

  background: linear-gradient(135deg, hsla(270, 72%, 69%, 1) 0%, hsla(149, 95%, 57%, 1) 100%);

  background: -webkit-linear-gradient(135deg, hsla(270, 72%, 69%, 1) 0%, hsla(149, 95%, 57%, 1) 100%);

  background-repeat: no-repeat;
  background-attachment: fixed;
}

.App {
  text-align: center;
}

h1 {
  font-size: 3em;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;;EAElC,2FAA2F;;EAI3F,mGAAmG;;EAEnG,4BAA4B;EAC5B,4BAA4B;AAC9B;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;AAChB","sourcesContent":["body {\n  background: hsla(270, 72%, 69%, 1);\n\n  background: linear-gradient(135deg, hsla(270, 72%, 69%, 1) 0%, hsla(149, 95%, 57%, 1) 100%);\n\n  background: -moz-linear-gradient(135deg, hsla(270, 72%, 69%, 1) 0%, hsla(149, 95%, 57%, 1) 100%);\n\n  background: -webkit-linear-gradient(135deg, hsla(270, 72%, 69%, 1) 0%, hsla(149, 95%, 57%, 1) 100%);\n\n  background-repeat: no-repeat;\n  background-attachment: fixed;\n}\n\n.App {\n  text-align: center;\n}\n\nh1 {\n  font-size: 3em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
