import { useState } from 'react';
import $ from 'jquery';
import '../App.css';
import './css/wheel.css'

export default function Wheel() {
  const [category_deg, setCategoryDeg] = useState(0);
  const [letter_deg, setLetterDeg] = useState(0);

  const [category, setCategory] = useState("");
  const [letter, setLetter] = useState("");

  const [result, setResult] = useState("");

  // const [final_cat = "";
  // const [final_letter,  = "";

  const [letters, setLetters] = useState("אבגדהוזחטיכלמנסעפצקרשת".split(''));
  const [cats, setCategories] = useState("שמן,ארץ,עיר,חי,צומח,שם של ילד,שם של ילדה,מקצוע,מפורסם".split(','));
  const colors = ["#FB5A37", "#FFB638","#F7FF38", "#65FF38", "#38FFF8", "#4238FF", "#B938FF", "#FF38DB", "#fc468f"];

  const spin = () => {
      // clear result
      setResult("")
      $(".info>button").addClass("hidden");
    

      // pick a category
      let cat_extra_turns = Math.ceil(Math.random() * 5) * 360;
      let cat_index = Math.ceil(Math.random() * (cats.length-1))+1;

      // rotate the wheel
      let cat_deg = 360 / cats.length;
      let rotate_cat = 320 + (cat_index * cat_deg) + cat_extra_turns;
      let ajusted_cat_index = cat_index-2;
      if (cat_index-2 < 0) {
        ajusted_cat_index += cats.length;
      }
      setCategory(cats[ajusted_cat_index]);
      setCategoryDeg(-rotate_cat);

      // pick a letter
      let letter_extra_turns = Math.ceil(Math.random() * 5) * 360;
      let letter_index = Math.ceil(Math.random() * (letters.length-1))+1;
      
      // rotate the wheel
      let letter_deg = 360 / letters.length;
      let rotate_letter = 370 + (letter_index * letter_deg) + letter_extra_turns;
      let ajusted_letter_index = letter_index-2;
      if (letter_index-2 < 0) {
        ajusted_letter_index += letters.length;
      }
      setLetterDeg(-rotate_letter);

      // set result
      setTimeout(function () {
        setLetter(letters[ajusted_letter_index]);
        setResult(cats[ajusted_cat_index] + " באות '" + letters[ajusted_letter_index] + "'");
        $(".info>button").removeClass("hidden");
        }, 3000);
  };

  const reload = () => {
    window.location.reload();
  };

  const remove_letter = () => {
    let letters_done = letters.filter(function (test_letter) {
      return test_letter !== letter;
  });
    setLetters(letters_done);
    let letter_deg = 360 / letters_done.length;
    $(".wheel_container>.letter.wheel>.letter").css("transform", "rotate(calc(" + letter_deg + "deg * var(--i)))")
  }


  let render_info = letters.length > 0 ? (
        <button class="remove hidden" onClick={remove_letter}>בטל</button>
      ): (
        <button class="remove hidden" onClick={reload}>מהתחלה</button>
      );

  let color_count = 0;
  return (
    <div class="container">
    <div class="wheel_container">
      <button class="spin" onClick={spin}>סובב</button>
      <div class="indcator"></div>
      <div class="cat wheel" style={{rotate: category_deg + "deg"}}>
        {
          cats.map((val, idx) => {
            if (color_count == colors.length-1) {
              color_count = 0;
            }else {
              color_count += 1;
            }
            return <div class="category" style={{"--i": idx, background: colors[color_count]}}><span>{val}</span></div>
          })
        }
      </div>
      <div class="letter wheel" style={{rotate: letter_deg + "deg"}}>
        {
          letters.map((val, idx) => {
            if (color_count+3 == colors.length-1) {
              color_count = 0;
            }else {
              color_count += 1;
            }
            return <div class="letter" style={{"--i": idx, background: colors[color_count + 3]}}><span>{val}</span></div>
          })
        }
    </div>
      </div>

      <div class="info">
        <p class="result">{result}</p>
        {render_info}
      </div>
    </div>
  )
}
